import React, { useEffect, useState } from 'react';
import { getCookie } from "../hooks/use-cookie";
import { FaEnvelope } from "react-icons/fa";

const SingleForm = ({ resourceUrl, selector, text }) => {
    const [isMarketing, setMarketing] = useState(false);
    const [enableOnce, setOnce] = useState(false);

    useEffect(() => {
        setInterval(() => {
            if (!isMarketing) {
                setMarketing(getCookie('rcl_marketing_consent'));
                console.log('refresh');
            }
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMarketing && !enableOnce) {
            const script = document.createElement('script');
            script.src = resourceUrl;
            script.async = true;
            document.getElementById(selector).prepend(script);
            setOnce(true);
        }
    }, [isMarketing])

    if (isMarketing) {
        return (
            <>
                <div className="col">
                <h2>Contact us</h2>
                { text ? <p>
                    {text}
                </p> : null }
                <div className="sendLinkContainer">
                    <div className="formDiv">
                        <FaEnvelope size={25} />
                        {/* Mautic form */}
                        <div id={selector} />
                    </div>
                </div>
                </div>
            </>
        );
    }
    else {
        return (
            <div className="col">
                <a href="/contact-us"><h2>Contact us</h2></a>
            </div>
        );
    }
}

export default SingleForm