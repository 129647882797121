import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youpage.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/page_1.svg"
import HeaderImg from "../../assets/img/youPage/headerImg.png"
import BuilderIcon from "../../assets/img/youPage/BuilderIcon.svg"
import AnalyticsIcon from "../../assets/img/youPage/AnalyticsIcon.svg"
import SectionsIcon from "../../assets/img/youPage/SectionsIcon.svg"
import EffortlessIcon from "../../assets/img/youPage/EffortlessIcon.svg"
import NoCodeIcon from "../../assets/img/youPage/NoCodeIcon.svg"
import GroupImg1 from "../../assets/img/youPage/LeftSide.png"
import FontIcon from "../../assets/img/youPage/FontIcon.svg"
import SEOIcon from "../../assets/img/youPage/SEOIcon.svg"
import TickIcon from "../../assets/img/youMedico/tick.svg"
import SingleForm from "../../components/singleForm"

const YoupagePage = () => {
  return (
    <div>
      <SEO title="Youpage" keywords={[`youpal`, `youpal group`, `youPage`]} />
      <div className="youPageMain products">
        <section className="container">
          <div className="row">

            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                An Easy Website Builder For Non-coders
                </h2>
              </div>
            </div>
            
            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
              Youpage is designed to help you create your website, without a single line of coding.
              </p>
            </div>
            
            <div className="col-12 order-4 order-md-3">
              <p className="internalNarrowCol mt-4">
              Customise websites based on your taste and preference and publish your content effortlessly with our website builder.
              </p>
            </div>
            
            <div className="col-12 order-5 order-md-4">
              <Link to="/products/request-demo">
              <button type="button" className="btn cta btn-primary px-5 mt-4 mt-md-5 mb-4 mb-md-0 mx-auto d-block">
                <span className="text-white">Free demo</span>
              </button>
              </Link>
            </div>

            <div className="col-12 order-2 order-md-5">
              <img className="headerImg" src={HeaderImg} />
            </div>

          </div>
        </section>

        <section className="container">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            Our Solution For Your Business
            </h3>
            <p className="internalNarrowCol">
            Youpage will take you through all the steps of website creation, landing page publishing, online store creation, blog publishing - all seamlessly integrated with other tools and software.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={BuilderIcon} />
              </div>
              <h4>User-friendly Website Builder</h4>
              <p>
              Choose a template or insert pre-designed blocks. Youpage gives you the tools and flexibility to build the website of your dreams.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={SectionsIcon} />
              </div>
              <h4>Pre-designed Sections</h4>
              <p>
              Choose pre-designed sections or simply modify them to suit your brand style.
              </p>
            </div>
          </div>
          <div className="row mt-0 mt-md-5">
            <div className="col">
              <div>
                <img src={EffortlessIcon} />
              </div>
              <h4>Effortless Responsiveness</h4>
              <p>
              Web to mobile friendly websites. No extra work, no redesigning. Youpage designs your website for ease of navigation and seamless responsiveness.
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left" style={{ textTransform: 'none' }}>
                  Youpage 
                </div>
                <h3 className="text-left">Look No Further. Here is Why</h3>
                <p>
                Youpage has all you need: design modules, content adding options, video and audio players, e-commerce features, hundreds of fonts, CRM, ticketing, integrated payment solutions, analytics, SEO tools, and personalization options. Let’s take a closer look at the most popular features of Youpage.
                </p>
                <div className="buttonArea">
            <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">Top Youpage Features</div>
              <h3>
                Stop postponing the creation of your website. It can be the
                breakthrough point for your business.
              </h3>
              <div className="row mt-md-5 mt-0">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={SEOIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>SEO Friendliness</h4>
                      <p>
                      The websites which appear on the first page of your search engine get maximum traffic. Search Engine Optimisation is your free ticket to new customers.
                      </p>
                      <p>
                      Youage website builder is programmed to make sure what you create is fully optimised.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={AnalyticsIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Google Analytics Integration</h4>
                      <p>
                      Track your webpage stats with Google Analytics integration. Grow your reach, grow your business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YoupagePage
